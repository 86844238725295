import React, { useState } from 'react';

import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import LogoAct from '../../assets/images/logo-act.png'
import './GraphRH.scss';

let classNames = require('classnames');

const GraphRH = ({ jobs, text, textColor, showActLogo = false }) => {
  const [vizSensorActive, setVizSensorActive] = useState('no');
  return (
    <div className="graph_rh_component">
      <div className="number_jobs">
        <VisibilitySensor delayedCall onChange={(isVisible) => { if (isVisible) { setVizSensorActive('yes') } }}>
          {({ isVisible }) =>
            <>
              <svg
                className={isVisible || vizSensorActive === 'yes' ? 'active' : ''}
                xmlns="http://www.w3.org/2000/svg"
                width={320.0690002441406}
                height={315.0150146484375}
                viewBox="0 0 320.069 315.015"
                aria-hidden="true"
              >
                <defs>
                  <style />
                </defs>
                <path
                  className="svg-elem-1"
                  d="M267.354 174.156a109.506 109.506 0 10-128.253 86.8 109.508 109.508 0 00128.253-86.8"
                  stroke="#fcec4f"
                  fill="none"
                  strokeMiterlimit={10}
                  strokeWidth="2px"
                />
                <path
                  className="svg-elem-2"
                  d="M199.885 0l-5.994 31.081a128.5 128.5 0 0153.877 217.772c-51.767 48.382-133.247 45.624-181.633-6.148a128.217 128.217 0 01-34.01-99.49L1.078 136.37a159.8 159.8 0 0041.956 127.923c60.292 64.512 161.818 67.946 226.326 7.661A159.959 159.959 0 00199.883 0"
                  fill="#a0c8d8"
                />
                <path
                  className="svg-elem-3"
                  d="M188.057 29.026q-2.03-.462-4.086-.856a127.563 127.563 0 00-149.4 101.114q-.873 4.527-1.413 9.036"
                  stroke="#a0c8d8"
                  fill="none"
                  strokeMiterlimit={10}
                  strokeWidth="2px"
                />
              </svg>
              <p className={classNames('inner_text', textColor)}>
                <span>{jobs}</span>
                {text.replace(/(<([^>]+)>)/ig, '')}
                {
                  showActLogo &&
                  <img
                    style={{ display: 'block', width: '100px', height: 'auto', margin: '0 auto' }}
                    //placeholder="blurred"
                    src={LogoAct}
                    alt="Act and Commit Together #ColasCSR2030"
                  />
                }
              </p>
            </>
          }
        </VisibilitySensor>
      </div>

    </div>
  );
};

GraphRH.propTypes = {
  text: PropTypes.string,
  jobs: PropTypes.string
};

GraphRH.defaultProps = {
  text: 'Default Text',
  jobs: '65',
  textColor: 'color_dark_bleu',
};

export default GraphRH;
